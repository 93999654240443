<template>
  <div style="padding: 20px;width: 800px">
    <div class="title">{{ data.title }} ( {{ new Intl.NumberFormat().format(data.value) }}đ / {{ new Intl.NumberFormat().format(data.max) }}đ )</div>
    <b-progress :max="data.max" height="2rem">
      <b-progress-bar :value="data.value"  :label="`${((data.value / data.max) * 100).toFixed(0)}%`">
      </b-progress-bar>
    </b-progress>
  </div>
</template>

<script>
import { BProgress, BProgressBar } from 'bootstrap-vue'

import io from 'socket.io-client'

export default {
  components: {
    BProgressBar,
    BProgress,
  },
  created() {
    this.socket = io(process.env.VUE_APP_SOCKET_SERVE)
    this.$http.get(`/widgets/token/${this.$route.params.id}`).then(({ data }) => {
      this.data = data.json
    })
      .catch(() => {
        this.$router.push({ name: 'error-404' })
      })
  },
  data() {
    return {
      socket: {},
      title: null,
      data: {
        value: 12,
        max: 30,
      },
    }
  },
  mounted() {
    this.socket.emit('join room', {
      id: this.$route.params.id,
    })

    this.socket.on('new_comment', data => {
      this.data = data.data
    })
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Calistoga&family=Josefin+Sans&display=swap');

[dir] .progress {
    background-color: rgb(38 205 196 / 52%);
    border-radius: 5rem;
}
html[dir] body{
  background: none !important;
}
.title {
  font-family: 'Calistoga', cursive;
  font-weight: bold;
  text-align: center;
  font-size: 22px;
  color: rgb(253, 112, 112);
  text-shadow: 2px 2px 10px #000000;
}
</style>
